<template>
  <div>
    <div v-if="!editing">
      <slot></slot>
    </div>

    <form
      v-else
      ref="form"
      method="POST"
      enctype="multipart/form-data"
      :action="action"
      @keydown.esc="lock()"
      class="flex flex-wrap -mx-1"
    >
      <input type="hidden" name="_method" :value="method" />
      <input type="hidden" name="_token" :value="csrf" />

      <textarea
        v-if="type === 'textarea'"
        v-model="value"
        :name="name"
        class="flex-grow m-1 input input--sm input--textarea text-xs"
        autofocus
        >{{ value }}</textarea
      >

      <input
        v-else-if="type === 'text'"
        v-model="value"
        :name="name"
        type="text"
        class="flex-grow m-1 input input--text input--sm text-xs"
        autofocus
      />

      <CleaveInput
        v-else-if="type === 'date'"
        type="date"
        v-model="value"
        :name="name"
        placeholder="DD/MM/YYYY"
        class="flex-grow m-1 input input--date input--sm text-xs"
      />

      <div v-else-if="type === 'datetime'" class="flex-grow m-1 flex">
        <CleaveInput
          v-model="date"
          :name="dateName"
          type="date"
          placeholder="DD/MM/YYYY"
          class="input input--date input--sm text-xs"
        ></CleaveInput>

        <CleaveInput
          v-model="time"
          :name="timeName"
          type="time"
          placeholder="HH:MM"
          class="ml-1 input input--date input--sm text-xs"
        ></CleaveInput>
      </div>

      <input
        v-else-if="type === 'file'"
        :name="name"
        type="file"
        class="flex-grow m-1 input input--date input--sm text-xs"
      />

      <button type="submit" class="flex-grow m-1 btn btn--sm btn--blue">
        Save
      </button>
    </form>
  </div>
</template>

<script>
import { cleaveDateOptions, cleaveTimeOptions } from '../cleaveOptions';
import CleaveInput from './Form/CleaveInput.vue';

export default {
  components: { CleaveInput },

  props: [
    'lockedClass',
    'action',
    'method',
    'csrf',
    'type',
    'initialValue',
    'dateName',
    'timeName',
    'name',
  ],

  data() {
    return {
      editing: false,
      value: this.initialValue,
      cleaveDateOptions,
      cleaveTimeOptions,
    };
  },

  computed: {
    date: {
      get() {
        return this.value.split('T')[0];
      },
    },
    time: {
      get() {
        return this.value.split('T')[1];
      },
    },
  },

  methods: {
    toggle() {
      if (this.editing) {
        this.lock();
      } else {
        this.unlock();
      }
    },
    unlock() {
      this.editing = true;
    },
    lock() {
      this.editing = false;
      this.value = this.initialValue;
    },
  },
};
</script>
