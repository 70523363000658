<template>
  <input v-model="inputValue" />
</template>
<script>
import { formatDate, formatTime } from 'cleave-zen';

export default {
  props: {
    type: String,
    modelValue: String,
  },

  data() {
    return {
      inputValue: null,
    };
  },

  created() {
    this.inputValue = this.modelValue;
  },

  watch: {
    inputValue: {
      handler(newValue) {
        if (!this.inputValue) return;

        if (this.type === 'date') {
          this.inputValue = formatDate(newValue, { ...this.cleaveDateOptions });
          this.$emit('update:modelValue', this.inputValue);
        } else if (this.type === 'time') {
          this.inputValue = formatTime(newValue, { ...this.cleaveTimeOptions });
          this.$emit('update:modelValue', this.inputValue);
        }
      },
      immediate: true,
    },
  },
};
</script>
